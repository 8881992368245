import { Redirect } from "react-router-dom";

function Home() {
    // return <div>
    //     <picture className="w-100">
    //         <source srcSet="https://imagedelivery.net/Jw5DM7t3QHRK6n5vKJihbA/682d41bf-d48d-4c11-8765-ad4d32d02100/960" media="(min-width: 960px)" />
    //         <source srcSet="https://imagedelivery.net/Jw5DM7t3QHRK6n5vKJihbA/682d41bf-d48d-4c11-8765-ad4d32d02100/2100" media="(min-width: 1024px)" />
    //         <img src="https://imagedelivery.net/Jw5DM7t3QHRK6n5vKJihbA/f0a7c9e1-54de-433d-ab8b-2612aea73600/512" alt="banner" className="w-100" />
    //     </picture>
    // </div>;
    return <Redirect to='/conference' />;
}

export default Home;