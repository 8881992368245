import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export function isAuthenticated(): boolean {
    if (sessionStorage.getItem('token')) {
        return true;
    } else {
        return false;
    }
}

export function setLoginData(data: any) {
    sessionStorage.setItem('token', data.token);
    sessionStorage.setItem('user_id', data.result.mygms_id);
    sessionStorage.setItem('user_name', data.result.name);
}

export function getApiInstance(): AxiosInstance {
    const payload = {
        baseURL: process.env.REACT_APP_API_URL
    } as AxiosRequestConfig<any>;
    const token = sessionStorage.getItem('token');
    // jika ada token
    if (token) {
        payload.headers = {
            Authorization: `Bearer ${token}`
        }
    }
    const instance = axios.create(payload);
    return instance;
}