import { useEffect, useState } from "react";
import "./conference-streaming.scss";
import { isAuthenticated } from "../components/helper";
import { FirebaseApp, initializeApp } from "firebase/app";
import {
    getDatabase,
    ref,
    onDisconnect,
    onValue,
    set,
    DatabaseReference,
    serverTimestamp,
} from "firebase/database";
import { doc, onSnapshot, getFirestore, getDoc } from "firebase/firestore";
import { getAnalytics, setUserId } from "firebase/analytics";
import Plyr from "plyr-react";
import { useLocation } from "react-router-dom";
declare var window: any;
const firebaseConfig = {
    apiKey: "AIzaSyAMR5Hao72OfHz5tghYV75BYkuqXTVg4us",
    authDomain: "gmschurch-aog.firebaseapp.com",
    databaseURL:
        "https://gmschurch-aog-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "gmschurch-aog",
    storageBucket: "gmschurch-aog.appspot.com",
    messagingSenderId: "147296698792",
    appId: "1:147296698792:web:176ac0830ed094baa9dd9d",
    measurementId: "G-S40W50CCCW",
};

// Initialize Firebase
const user = getUserDetail();

function getUserDetail() {
    return {
        id: sessionStorage.getItem("user_id"),
        name: sessionStorage.getItem("user_name"),
    };
}

var presenceRef: DatabaseReference;
var connectedRef: DatabaseReference;
var app: FirebaseApp;
function init() {
    app = initializeApp(firebaseConfig);
    const db = getDatabase(app);
    const analytics = getAnalytics(app);
    analytics.app.automaticDataCollectionEnabled = true;
    const userId = sessionStorage.getItem("user_id");
    if (userId) {
        try {
            setUserId(analytics, userId);
        } catch (exc) {
            console.error("Error setting user_id for analytics");
        }
    }
    // untuk kirimkan status kalau user sedang online, terpakai untuk cek double login
    presenceRef = ref(db, "users/" + user.id);
    connectedRef = ref(db, ".info/connected");
    onValue(connectedRef, (snap) => {
        if (snap.val() === true) {
            console.log("connected");
            set(presenceRef, {
                isOnline: true,
                firstSeenAt: serverTimestamp(),
            })
                .then((o) => {
                    console.log(o);
                })
                .catch((e) => {
                    console.error("Error writing to realtime database");
                    console.error(e);
                });
            // onDisconnect di call dari server firebase ketika user tidak connect
            onDisconnect(presenceRef).update({
                isOnline: false,
                lastSeenAt: serverTimestamp(),
            });
        } else {
            console.log("not connected");
        }
    });
}
// variable untuk simpan daftar link streaming
let STREAM_ID = "";
let STREAM_EN = "";
let STREAM_CN = "";
const FIREBASE_DOC = "aog-conference";

function ConferenceStreaming() {
    const [youtubeCode, setYoutubeCode] = useState("");
    const [isLive, setIsLive] = useState(false);
    // const [bunnyVideoUrl, setBunnyVideoUrl] = useState('');
    const [currentLang, setCurrentLang] = useState("ID"); // default bahasa adalah ID

    // pengecekan untuk debug
    const query = new URLSearchParams(useLocation().search);
    // jika ada pengaturan bahasa
    const debug = query.get("debug") === "1" ? true : false;
    let debugIsLive = debug && query.get("live") === "1" ? true : false;

    const changeLang = (lang: string) => {
        setCurrentLang(lang);
        switch (lang) {
            case "ID":
                setYoutubeCode(STREAM_ID);
                break;
            case "EN":
                setYoutubeCode(STREAM_EN);
                break;
            case "CN":
                setYoutubeCode(STREAM_CN);
                break;
        }
    };

    // pengaturan untuk live streaming
    const startLiveStreaming = () => {
        // set link youtube sesuai bahasa
        switch (currentLang) {
            case "ID":
                setYoutubeCode(STREAM_ID);
                break;
            case "EN":
                setYoutubeCode(STREAM_EN);
                break;
            case "CN":
                setYoutubeCode(STREAM_CN);
                break;
        }
        // set status agar tampilan berubah ke mode live streaming
        setIsLive(true);
    };

    // pengaturan saat masih menunggu event dimulai
    const waitStreaming = () => {
        setIsLive(false);
    };

    const renderPlayer = () => {
        if (isLive) {
            return (
                <div
                    className="plyr__video-embed streaming-player"
                    id="playerstreaming"
                >
                    <Plyr
                        source={{
                            type: "video",
                            sources: [
                                {
                                    src: youtubeCode,
                                    provider: "youtube",
                                },
                            ],
                        }}
                        options={{
                            clickToPlay: false,
                            hideControls: true,
                            youtube: {
                                showinfo: 0,
                                controls: 0,
                                rel: 0,
                                mute: 1,
                            },
                            controls: [
                                "play-large",
                                "mute", // Toggle mute
                                "volume", // Volume control
                                "fullscreen", // Toggle fullscreen
                            ],
                        }}
                    />
                </div>
            );
        } else {
            return (
                <div className="streaming-player">
                    <img
                        src="https://aog-conf.b-cdn.net/play-thumbnail.jpg"
                        alt="player"
                        className="player-waiting"
                    />
                </div>
            );
        }
    };

    useEffect(() => {
        if (isAuthenticated()) {
            // default init
            init();
            if (debug) {
                // baca 1x untuk ambil channel streaming
                const fdb = getFirestore(app);
                getDoc(doc(fdb, "streaming", FIREBASE_DOC)).then((doc) => {
                    const data = doc.data();
                    if (!data) {
                        // jika undefined, skip
                        return;
                    }
                    STREAM_ID = data.channel_id;
                    STREAM_EN = data.channel_en;
                    STREAM_CN = data.channel_cn;
                    // mulai pengecekan debug
                    if (debugIsLive) {
                        startLiveStreaming();
                    } else {
                        waitStreaming();
                    }
                });
            } else {
                // init firestore untuk cek status streaming
                const fdb = getFirestore(app);
                // listen update doc
                onSnapshot(doc(fdb, "streaming", FIREBASE_DOC), (doc) => {
                    const data = doc.data();
                    if (!data) {
                        // jika undefined, skip
                        return;
                    }
                    STREAM_ID = data.channel_id;
                    STREAM_EN = data.channel_en;
                    STREAM_CN = data.channel_cn;
                    if (data.isLive) {
                        startLiveStreaming();
                    } else {
                        waitStreaming();
                    }
                });
            }
        }
    }, []);

    if (!isAuthenticated()) {
        window.location.href = "/conference/login";
        return <div></div>;
    } else {
        return (
            <div>
                <div className="streaming-container">
                    <div id="header" className="text-center">
                        <div className="relative py-4">
                            <img
                                className="logo-left"
                                src="https://aog-conf.b-cdn.net/header-logo-1.webp"
                                alt="logo-left"
                            />
                            <img
                                className="logo"
                                src="https://aog-conf.b-cdn.net/logo.png"
                                alt="logo"
                            />
                        </div>
                    </div>
                    <div className="tile-1x"></div>
                    <div className="d-lg-flex justify-content-center mt-3">
                        <div id="playerContainer">
                            {renderPlayer()}
                            {isLive && (
                                <div className="chat-change-lang d-none d-lg-block mt-3 ms-2">
                                    <button
                                        className="btn"
                                        onClick={(_) => changeLang("ID")}
                                        style={{
                                            backgroundColor:
                                                "rgb(169, 204, 145)",
                                            color: "white",
                                            marginLeft: "0%",
                                            marginRight: "1%",
                                        }}
                                    >
                                        <h4>
                                            <strong>Indonesia</strong>
                                        </h4>
                                    </button>
                                    <button
                                        className="btn"
                                        onClick={(_) => changeLang("EN")}
                                        style={{
                                            backgroundColor:
                                                "rgb(169, 204, 145)",
                                            color: "white",
                                            marginLeft: "1%",
                                            marginRight: "1%",
                                        }}
                                    >
                                        <h4>
                                            <strong>English</strong>
                                        </h4>
                                    </button>
                                    <button
                                        className="btn"
                                        onClick={(_) => changeLang("CN")}
                                        style={{
                                            backgroundColor:
                                                "rgb(169, 204, 145)",
                                            color: "white",
                                            marginLeft: "1%",
                                            marginRight: "1%",
                                        }}
                                    >
                                        <h4>
                                            <strong>Chinese</strong>
                                        </h4>
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="tile-2x d-block d-lg-none"></div>
                        <div className="chat-wrapper">
                            {isLive && (
                                <div
                                    className="chat-change-lang d-block d-lg-none"
                                    style={{
                                        padding: "auto",
                                    }}
                                >
                                    <button
                                        className="btn"
                                        onClick={(_) => changeLang("ID")}
                                        style={{
                                            backgroundColor:
                                                "rgb(169, 204, 145)",
                                            color: "white",
                                            marginLeft: "0%",
                                            marginRight: "1%",
                                        }}
                                    >
                                        <h4>
                                            <strong>Indonesia</strong>
                                        </h4>
                                    </button>
                                    <button
                                        className="btn"
                                        onClick={(_) => changeLang("EN")}
                                        style={{
                                            backgroundColor:
                                                "rgb(169, 204, 145)",
                                            color: "white",
                                            marginLeft: "1%",
                                            marginRight: "1%",
                                        }}
                                    >
                                        <h4>
                                            <strong>English</strong>
                                        </h4>
                                    </button>
                                    <button
                                        className="btn"
                                        onClick={(_) => changeLang("CN")}
                                        style={{
                                            backgroundColor:
                                                "rgb(169, 204, 145)",
                                            color: "white",
                                            marginLeft: "1%",
                                            marginRight: "1%",
                                        }}
                                    >
                                        <h4>
                                            <strong>Chinese</strong>
                                        </h4>
                                    </button>
                                </div>
                            )}
                            <iframe
                                src={
                                    "https://organizations.minnit.chat/320737287770383157/Main?embed&&nickname=" +
                                    user.name
                                }
                                title="Live Chat"
                                style={{ border: "none", width: "100%" }}
                                id="chat_box"
                                allowTransparency={true}
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="tile-2x"></div>
                </div>
            </div>
        );
    }
}

export default ConferenceStreaming;
