import { useEffect } from "react";
import "./login.scss";
import { useLocation } from "react-router-dom";
import React from "react";
import { getApiInstance, setLoginData } from "../components/helper";
import Swal from 'sweetalert2';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function Login() {
    let query = useQuery();
    useEffect(() => {
        if (query.has('code')) {
            // tukar dengan data login
            const instance = getApiInstance();
            const endpoint = '/conference/auth';
            const formData = new FormData();
            formData.append('auth_code', query.get('code')!);
            instance.post(endpoint, formData).then(res => {
                setLoginData(res.data);
                window.location.replace('/conference/streaming');
            }).catch(exc => {
                console.error('Error at log in!');
                console.error(exc);
                if (exc.response) {
                    if (exc.response.status === 500) {
                        Swal.fire('Error', 'Something went wrong!', 'error');
                    } else if (exc.response.status === 403) {
                        Swal.fire('Error', exc.response.data.error, 'warning').then(_ => {
                            window.location.replace('/conference');
                        });
                    } else {
                        Swal.fire('Error', exc.response.data.error, 'warning').then(_ => {
                            window.location.replace('/conference');
                        });;
                    }
                }
            });
        } else if (query.has('u')) { // bypass untuk user dengan token khusus
            const instance = getApiInstance();
            const endpoint = '/conference/guest-auth';
            const formData = new FormData();
            formData.append('u', query.get('u')!);
            instance.post(endpoint, formData).then(res => {
                setLoginData(res.data);
                window.location.replace('/conference/streaming');
            }).catch(exc => {
                console.error('Error at log in!');
                console.error(exc);
                if (exc.response) {
                    if (exc.response.status === 500) {
                        Swal.fire('Error', 'Something went wrong!', 'error');
                    } else if (exc.response.status === 403) {
                        Swal.fire('Error', exc.response.data.error, 'warning').then(_ => {
                            window.location.replace('/conference');
                        });
                    } else {
                        Swal.fire('Error', exc.response.data.error, 'warning').then(_ => {
                            window.location.replace('/conference');
                        });;
                    }
                }
            });
        } else {
            // kalau belum ada code, redirect ke GMS Account untuk login
            const clientId = 'd26f668b4af16b355b085703c0382199';
            const redirectUrl = process.env.REACT_APP_BASE_URL + '/conference/login';
            const loginUrl = `https://accounts.gms.church/oauth/authorize?scope=profile&state=state&client_id=${clientId}&redirect_uri=${redirectUrl}`;
            window.location.replace(loginUrl);
        }
    }, [query]);
    return (<div id="mainWrapper" className="container">
        <div className="loader-wrapper">
            <div className="loader-icon">
                <img src="https://gmschurch-assets-2.b-cdn.net/loader/Tail-3s-64px.svg" alt="loading" />
                <div>
                    Please wait...
                </div>
            </div>
        </div>
    </div>);
}