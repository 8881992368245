import "./App.scss";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation,
} from "react-router-dom";
import { Suspense, useEffect } from "react";
import i18n from "./i18n";
import React from "react";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Loading from "./pages/Loading";
import ConferenceStreaming from "./pages/ConferenceStreaming";
import ReactGA from "react-ga";
const Conference = React.lazy(() => import("./pages/Conference"));
// const ConferenceStreaming = React.lazy(() => import('./pages/ConferenceStreaming'));
const ConferenceOnDemand = React.lazy(
    () => import("./pages/ConferenceOnDemand")
);
const TRACKING_ID = process.env.REACT_APP_GA_CODE;
if (TRACKING_ID) {
    ReactGA.initialize(TRACKING_ID);
}

function App() {
    // ambil query
    const query = new URLSearchParams(useLocation().search);
    // jika ada pengaturan bahasa
    const lang = query.get("lang");
    // jika ada parameter bahasa
    if (lang) {
        sessionStorage.setItem("lang", lang);
    } else if (!sessionStorage.getItem("lang")) {
        // hanya set bila belum ada isinya
        sessionStorage.setItem("lang", "id"); // default bahasa adalah id
    }
    // set current language
    i18n.changeLanguage(sessionStorage.getItem("lang")!);

    useEffect(() => {
        if (TRACKING_ID) {
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, []);

    return (
        <Suspense fallback={<Loading></Loading>}>
            <Router>
                <div className="App">
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route
                            exact
                            path="/conference"
                            component={Conference}
                        />
                        <Route
                            exact
                            path="/conference/login"
                            component={Login}
                        />
                        <Route
                            exact
                            path="/conference/streaming"
                            component={ConferenceStreaming}
                        />
                        <Route
                            exact
                            path="/conference/streaming/demand"
                            component={ConferenceOnDemand}
                        />
                    </Switch>
                </div>
            </Router>
        </Suspense>
    );
}

export default App;
